body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d) no-repeat;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imageContainer{
  width: 25%;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

/* Custom CSS for spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  animation: spin 1s linear infinite;
}

/* LandingPage.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.landing-page {
  font-family: 'Roboto', sans-serif;
  
  background: linear-gradient(135deg, #833ab4, #fd1d1d, #ffaf29);
  color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.hero {
  text-align: center;
  margin-bottom: 60px;
}

.title {
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 24px;
  font-weight: 300;
  color: #f0f0f0;
  margin-bottom: 30px;
}

.cta {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn {
  padding: 15px 40px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #fff;
  color: #007bff;
}

.btn-primary:hover {
  background-color: #f0f0f0;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.features {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .features {
    flex-direction: column;
  }
  .cta{
    flex-direction: column;
  }
}

.feature {
  text-align: center;
  max-width: 300px;
}

.feature i {
  color: #fff;
  font-size: 50px;
  margin-bottom: 20px;
}

.feature h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature p {
  font-size: 16px;
  color: #f0f0f0;
}

.footer {
  font-size: 14px;
  color: #f0f0f0;
  margin-top: auto;
  text-align: center;
}

.privacy-policy, .terms-of-service {
  height: 100vh;
  overflow: auto;
}